<template>
  <router-view></router-view>
  <h1>Base Price Profile</h1>
  <hr/>
  <bs-error-message :errors="errorMessages"/>

  <div class="row justify-content-between">
    <div class="col-md-6">
      <div class="d-grid gap-3 d-md-block">
        <router-link to="/base-price-profile/create" class="btn btn-primary btn-sm me-md-1">
          <i class="bi bi-file-earmark"></i> Create
        </router-link>
      </div>
    </div>
    <div class="col-md-6">
      <div class="pagination-right">
        <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow"
                       @toPage="toPage"/>
      </div>
    </div>
  </div>

  <div class="">
    <table class="table table-striped">
      <thead>
      <tr>
        <th rowspan="2">
          Action
        </th>
        <th>
          <bs-ordering property="nama" @reorder="reorder">Nama</bs-ordering>
        </th>
        <th>
          <bs-ordering property="timeRangeId" @reorder="reorder">Jenis Waktu</bs-ordering>
        </th>
        <th>
          <bs-ordering property="startDay" @reorder="reorder">Hari Mulai</bs-ordering>
        </th>
        <th>
          <bs-ordering property="locationId" @reorder="reorder">Lokasi</bs-ordering>
        </th>
        <th>
          <bs-ordering property="currencyId" @reorder="reorder">Mata Uang</bs-ordering>
        </th>
        <th>
          <bs-ordering property="unitId" @reorder="reorder">Satuan Unit</bs-ordering>
        </th>

      </tr>
      <tr>
        <th>
          <div>
            <bs-textbox placeholder="Nama" v-model="filter.namaContains" @keyup="search()"/>
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Jenis Waktu" v-model="filter.timeRangeContains" @keyup="search()"/>
          </div>
        </th>
        <th>
          <div>
            <start-day-combo v-model="filter.startDayEquals" :textDefault="filter.startDayEqualsName" @update:model-value="search()"/>
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Lokasi" v-model="filter.locationCodeContains" @keyup="search()"/>
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Mata Uang" v-model="filter.currencyCodeContains" @keyup="search()"/>
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Satuan Unit" v-model="filter.unitCodeContains" @keyup="search()"/>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-if="data != null && data.length > 0">
        <tr v-for="item in data" :key="item.id">
          <td>
            <div class="btn-group">
              <button class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-menu-down"></i> Action
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button @click="detailItem(item)" class="dropdown-item"><i class="bi bi-eye"></i> View Details
                  </button>
                </li>
                <li>
                  <button @click="editItem(item)" class="dropdown-item"><i class="bi bi-pencil-square"></i> Edit
                  </button>
                </li>
                <li>
                    <button @click="deleteItem(item)" class="dropdown-item"><i class="bi bi-trash"></i> Delete</button>
                </li>
              </ul>
            </div>
          </td>
          <td>
            {{ item.nama }}
          </td>

          <td>
            {{ item.timeRangeNama }}
          </td>

          <td>
            {{ item.startDayName }}
          </td>

          <td>
            {{ item.locationCode }}
          </td>

          <td>
            {{ item.currencyCode }}
          </td>

          <td>
            {{ item.unitCode }}
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import BasePriceProfileService from "@/services/base-price-profile-service";
import StartDayCombo from "@/components/StartDayCombo.vue";
// import SecurityService from "@/services/security-service.js";

export default {
  components: {StartDayCombo},
  data: function () {
    return {
      filter: {
        codeContains: '',
        startDayEquals:0,
        startDayEqualsName:null,
        timeRangeContains:'',
        locationCodeContains:'',
        currencyCodeContains:'',
        unitCodeContains:'',
        page: 1,
        perPage: 10,
        orderByProperty: 'id',
        orderType: 0
      },
      errorMessages: null,
      pageNumber: 1,
      perPage: 10,
      totalPage: 100,
      totalRow: 0,
      data: Array(),
      isAuthCreate: false,
      isAuthEdit: false
    }
  },
  watch: {
    $route: function () {
      if (this.$route.path == "/base-price-profile") {
        this.loadData();
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async toPage(pageNumber) {
      this.filter.page = pageNumber;
      this.loadData();
    },
    async reorder(property, orderType) {
      this.filter.orderByProperty = property;
      this.filter.orderType = orderType;
      this.loadData();
    },
    async search() {
      this.filter.page = 1;
      this.loadData();
    },
    async loadData() {
      const result = await BasePriceProfileService.getList(this.filter);
      if (result.status) {
        this.errorMessages = null;
      } else {
        this.errorMessages = result.errorMessages;
      }

      const data = result.data;
      this.pageNumber = data.pageNumber;
      this.perPage = data.perPage;
      this.totalPage = data.totalPage;
      this.totalRow = data.totalRow;
      this.data = data.data;
    },
    detailItem(item) {
      this.$router.push(`/base-price-profile/detail/${item.id}`);
    },
    editItem(item) {
      this.$router.push(`/base-price-profile/edit/${item.id}`);
    },
    async deleteItem(item) {
       if (confirm(`Are you sure want to delete ${item.nama} ?`)) {
           const result = await BasePriceProfileService.delete(item.id);
           if (result.status) {
               this.loadData();
           }
       }
    }
  }
}
</script>

<style>

</style>